import React from "react";

import { Link } from "gatsby";

import ArrowRightBlackIcon from "../icons/ArrowRightBlackIcon";
import ArrowRightCircleIcon from "../icons/ArrowRightCircleIcon";

import SEO from "~/src/components/SEO";
import Container from "~/src/components/shared/Container";

const ProviderOnboarding = () => {
  return (
    <Container>
      <SEO title="Bienvenid@ a Examedi" />
      <div className="flex flex-col items-center justify-center">
        <div className="h-screen flex items-center justify-center w-full">
          <div>
            <div className="text-3xl text-center text-gray-700">Soy Sofía, enfermera jefe en Examedi.</div>
            <div className="text-lg mt-4 text-center">
              Examedi le facilita te facilita la vida a tí y a tus pacientes.
            </div>
            <center>
              <div className="mt-6 mx-auto">
                <Link to="/provider-onboarding">
                  <button className="text-blue-700 rounded-full bg-blue-100 font-medium hover:bg-blue-200 transition ease-in-out py-4 px-8 mt-4 flex items-center">
                    Continuar <ArrowRightCircleIcon className="ml-[10px]" />
                  </button>
                </Link>
              </div>
            </center>
          </div>
        </div>

        {/* New Section */}
        <div className="h-screen flex items-center justify-center">
          <div>
            <div className="text-3xl text-center text-gray-700">
              Te quiero conocer un poco más,
              <br /> ¿Cómo te describirías?
            </div>
            <div className="mt-6">
              {[
                { text: "Soy médico y quiero que atiendan a mis pacientes" },
                { text: "Tengo una empresa de salud y quiero usar su API" },
                { text: "Queremos derivar pacientes a Examedi" },
              ].map((item: any, key: number) => (
                <div
                  key={key}
                  className="text-lg text-blue-700 mb-[5px] text-sm p-5 bg-blue-100 w-[100%]  hover:cursor-pointer hover:bg-blue-200 rounded-md"
                >
                  {item.text}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* New Section */}
        <div className="h-screen flex items-center justify-center">
          <div>
            <div className="text-3xl text-center text-gray-700">
              Finalmente, quiero saber
              <br /> ¿cuántos pacientes atiendes al día?
            </div>
            <div className="mt-6">
              {[{ text: "Entre 1-10" }, { text: "Entre 11 y 50" }, { text: "Más de 50" }].map(
                (item: any, key: number) => (
                  <div
                    key={key}
                    className="text-lg text-blue-700 mb-[5px] text-sm p-5 bg-blue-100 w-[100%]  hover:cursor-pointer hover:bg-blue-200 rounded-md"
                  >
                    {item.text}
                  </div>
                ),
              )}
            </div>
          </div>
        </div>

        <div className="h-screen flex items-center justify-center w-full">
          <div>
            <div className="text-3xl text-center text-gray-700">¡Bienvenid@ a Examedi!</div>
            <div className="text-lg text-center mt-4">El futuro de la atención domiciliaria</div>
            <center>
              <div className="mt-6 mx-auto">
                <Link to="/app/patients">
                  <button className="text-blue-700 rounded-full bg-blue-100 font-medium hover:bg-blue-200 transition ease-in-out py-4 px-8 mt-4 flex items-center">
                    Ir a la plataforma <ArrowRightCircleIcon className="ml-[10px]" />
                  </button>
                </Link>
              </div>
            </center>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ProviderOnboarding;
